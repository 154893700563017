import React, {
  createContext,
  useReducer,
  useContext,
  useCallback
} from 'react';
import { CardProps } from '../components/molecule/Card/interface';

type CardStore = {[key: string]: CardProps}
interface CartInitialStates {
  store: CardStore
}

export enum ACTION_TYPES {
  ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  CLEAR_CART = 'CLEAR_CART'
}

type CartActions =
  | { type: ACTION_TYPES.ADD_TO_CART, payload: CardProps }
  | { type: ACTION_TYPES.REMOVE_FROM_CART, payload: CardProps }
  | { type: ACTION_TYPES.CLEAR_CART }

export type GroupedCartItems = { items: CardProps []; title: string};
type ApiCardItem = {
  title: string;
  link: string;
  _id: string
}

  const getInitialState = (): CartInitialStates => {
    try {
      return JSON.parse(localStorage.accessStoreCart);
    } catch (error) {
      return ({
        store: {}
      })
    }
  }


  const saveStateInLocalStorage = (state: CartInitialStates) => {
    localStorage.setItem('accessStoreCart', JSON.stringify(state))
  }

function reducer(prevState: CartInitialStates, action: CartActions) {
  switch (action.type) {
    case ACTION_TYPES.ADD_TO_CART:
    {
      const newState = {store: { ...prevState.store, [action?.payload?.uniqueRef]: action?.payload }};
      saveStateInLocalStorage(newState)
      return newState
    };
    case ACTION_TYPES.REMOVE_FROM_CART:
    {
        const newStore = { ...prevState.store}
        if(prevState?.store[action?.payload?.uniqueRef])  {
          delete newStore[action?.payload?.uniqueRef]; 
        }
        const newState = { store: newStore };
        saveStateInLocalStorage(newState)
        return newState;
    };
    case ACTION_TYPES.CLEAR_CART:
    {
      const newState = {store: {}};
      saveStateInLocalStorage(newState)
      return newState
    };
  }
}

export type IBasket = {[key: string]: ApiCardItem []}

export type ICartRecords = {
  store?: CardStore;
  storeLength: number;
  onCartClick?: (rec: CardProps) => void;
  isInCart?: (inp: string) => boolean;
  groupStoreByCountry?: () => GroupedCartItems [];
  getApiData?: () => (IBasket);
  clearCart?: () => void;
}

// const CONTEXT_ERROR =
//   'Cart context not found. Have you wrapped your components with CartProvider?';


const CartContext = createContext<ICartRecords>({
  storeLength: 0,
});

const CartProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const onCartClick = useCallback(
    (record: CardProps) => {
      
      if(state?.store[record?.uniqueRef]) {
        dispatch({
          type: ACTION_TYPES.REMOVE_FROM_CART,
          payload: record
        })
      } else {
        dispatch({
          type: ACTION_TYPES.ADD_TO_CART,
          payload: record
        })
      }
    },
    [state?.store],
  ) 

  const isInCart = useCallback(
    (uniqueRef: string) => {
      return !!state.store[uniqueRef]
    },
    [state?.store],
  ) ;

  const groupStoreByCountry = useCallback(
    () => {
      const store = Object.keys(state?.store);
      const storeByCountry: {[key: string]: GroupedCartItems} = {};
      store.forEach(key => {
        const record: CardProps = state?.store[key];
        if(!storeByCountry[record?.cardType]) {
          storeByCountry[record?.cardType] = {title: record?.cardType, items: []};
        }
        storeByCountry[record?.cardType].items.push(record);
      });
      return Object.values(storeByCountry);
    },
    [state?.store],
  )

  const getApiData = useCallback(
    () => {
      const store = Object.keys(state?.store);
      const apiData: IBasket = {};
      store.forEach(key => {
        const record: CardProps = state?.store[key];
        if(!apiData[record?.cardType]) {
          apiData[record?.cardType] = [];
        }
        apiData[record?.cardType]?.push({title: record?.title, link: record?.ctaUrl || "", _id: record?.uniqueRef });
      });
      return apiData;
    },
    [state?.store],
  );

  const clearCart = useCallback(
    () => {
      dispatch({
        type: ACTION_TYPES.CLEAR_CART
      })
    },  
    [],
  );
  const CartRecords: ICartRecords = {
    store: state.store,
    storeLength: Object.keys(state.store)?.length || 0,
    onCartClick: onCartClick || null,
    isInCart: isInCart || null,
    groupStoreByCountry: groupStoreByCountry || null,
    clearCart: clearCart || null,
    getApiData: getApiData || null
  }

  return (
    <CartContext.Provider value={CartRecords}>
      {children}
    </CartContext.Provider>
  )
}


const useCartContext = () => {
  return useContext(CartContext);
}

export { useCartContext, CartProvider };
