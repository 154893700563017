import React, {
  createContext, 
  Dispatch,
  useReducer,
  useContext
} from 'react';

import { ContentShape, lang, CounrtryIntroImageShape, SiteConfig, AllSlugs } from '../entities/queriesTypes'
import { SubNavItem } from '../entities/pagepart'
import { CurrentView } from '../entities/templates'
import {locales} from '../../config'

const initialState: SiteInitialStates = {
  lang: null,
  countryName: null,
  subTitleData: null,
  currentView: null,
  allLocales: null,
  allSlugs: null,
  heroImageViewImage: null,
  pagePartView: false
}

interface SiteInitialStates {
  lang?: locales | null,
  countryName?: string|null,
  heroImageTitle?: string|null,
  subTitleData?: SubTitleData|null,
  currentView?: CurrentView|null,
  allLocales?: PartialLocales|null,
  allSlugs?: AllSlugs|null,
  heroImageViewImage?: CounrtryIntroImageShape|null,
  siteConfig?: SiteConfig | null,
  subMenu?: SubMenu
  pagePartView?: boolean
  subNavigationItem?: SubNavItem[]
  subNavigationTitle?: {
    [key in lang]: string
  }
}

type SubMenu = {
  title?: string
}

export enum ACTION_TYPES  {
  SET_LANG = 'LANG',
  SET_COUNTRY = 'COUNTRY',
  SET_HERO_IMAGE_TITLE = 'HERO_IMAGE_TITLE',
  SET_SUB_TITLE_DATA = 'SUB_TITLE_DATA',
  SET_CURRENT_VIEW = 'CURRENT_VIEW',
  SET_ALL_LOCALES = 'ALL_LOCALES',
  SET_ALL_SLUGS = 'ALL_SLUGS',
  SET_HERO_IMAGE_VIEW_IMAGE = 'HERO_IMAGE_VIEW_IMAGE',
  SET_SITE_CONFIG= 'SITE_CONFIG',
  SET_SUBMENU_TITLE= 'SET_SUBMENU_TITLE',
  SET_IS_PAGE_PART_VIEW= 'SET_IS_PAGE_PART_VIEW',
  SET_PAGE_SUBNAVIGATION_LIST ='SET_PAGE_SUBNAVIGATION_LIST',
  SET_PAGE_SUBNAVIGATION_TITLE ='SET_PAGE_SUBNAVIGATION_TITLE',
}

type SiteActions = 
| { type: ACTION_TYPES.SET_LANG, key: 'lang', payload: locales } 
| { type: ACTION_TYPES.SET_COUNTRY, key: 'countryName', payload: string } 
| { type: ACTION_TYPES.SET_HERO_IMAGE_TITLE, key: 'heroImageTitle', payload: string }
| { type: ACTION_TYPES.SET_SUB_TITLE_DATA, key: 'subTitleData', payload: SubTitleData }
| { type: ACTION_TYPES.SET_CURRENT_VIEW, key: 'currentView', payload: CurrentView }
| { type: ACTION_TYPES.SET_ALL_LOCALES, key: 'allLocales', payload: PartialLocales }
| { type: ACTION_TYPES.SET_ALL_SLUGS, key: 'allSlugs', payload: AllSlugs }
| { type: ACTION_TYPES.SET_HERO_IMAGE_VIEW_IMAGE, key: 'heroImageViewImage', payload: CounrtryIntroImageShape }
| { type: ACTION_TYPES.SET_SITE_CONFIG, key: 'siteConfig', payload: SiteConfig }
| { type: ACTION_TYPES.SET_IS_PAGE_PART_VIEW, key: 'pagePartView', payload: boolean }
| { type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_LIST, key: 'subNavigationItem', payload: SubNavItem[] }
| { type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_TITLE, key: 'subNavigationTitle', payload: SiteInitialStates['subNavigationTitle'] }
| { type: ACTION_TYPES.SET_SUBMENU_TITLE, key: 'submenuTitle', payload: SubMenu['title'] };

function reducer (state: SiteInitialStates, action: SiteActions) {
  const actionTypes = Object.values(ACTION_TYPES);
  if (action.type === ACTION_TYPES.SET_SUBMENU_TITLE) {
    return {
      ...state,
      subMenu: {
        title: action.payload
      }
    }
  }
  else if (actionTypes.includes(action.type)) {
    return {
      ...state,
      [action.key]: action.payload
    }
  }
  throw new Error('Action type doesnt exist');
}

type PartialLocales = lang[]

export type SiteGlobals = {
  state?: SiteInitialStates,
  stateDispatch?: Dispatch<SiteActions>
}


type SubTitleData = ContentShape[lang]

const SiteContext = createContext<SiteGlobals>({});

const SiteProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SiteContext.Provider value={
      {
        state,
        stateDispatch: dispatch
      }
    }>
      {children}
    </SiteContext.Provider>
  )
}


const useGlobalStateContext = () => {
  return useContext(SiteContext);
}

export { useGlobalStateContext , SiteProvider };
