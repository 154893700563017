import React from 'react';
import { SiteProvider } from './src/Context/site';
import { CartProvider } from './src/Context/CartContext';
// import GoogleTranslate from './gatsby-google-comp';

import './src/styles/app.css';
import './src/styles/tailwind.css'
import 'animate.css'

export const wrapRootElement = ( { element }) => {
  return  (
    <SiteProvider>
      <CartProvider>
        {/* <GoogleTranslate /> */}
        {element}
      </CartProvider>
    </SiteProvider>
 )
}
